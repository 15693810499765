import React from 'react'
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import _ from 'lodash'
import { RouteComponentProps } from 'react-router'
import Validator from 'validator'
import { API } from '../../api/api'
import withAppCanvas from '../../components/AppCanvas'
import { MenuItemType } from '../../model/MenuItemType'
import { AppStateContext } from '../../state/appStateContext'
import { changeMenuItem } from '../../state/menuItemActions'
import { setTitle } from '../../state/titleActions'
import { UserCreateRequest, UserEditRequest } from '../../api/request'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { routesDetails } from '../../routes/routesDetails'
import { showMessage } from '../../state/messageActions'
import { User } from '../../api/response'

//#region UI
const strings = {
    button: {
        save: 'Zapisz',
    },
    error: {
        emailRequired: 'Adres email jest wymagany',
        emailLength: 'Wymagana wartość w przedziale <1, 255> znaków',
        emailInvalid: 'Proszę podać poprawny adres email',
        passwordRequired: 'Hasło jest wymagane',
        passwordLength: 'Minimalna długość to 8 znaków',
        rePasswordInvalid: 'Hasła nie są takie same',
        firstNameRequired: 'Imię jest wymagane',
        firstNameLength: 'Wymagana wartość w przedziale <1, 128> znaków',
        lastNameRequired: 'Nazwisko jest wymagane',
        lastNameLength: 'Wymagana wartość w przedziale <1, 128> znaków',
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
    label: {
        email: 'Email',
        password: 'Hasło',
        rePassword: 'Powtórz hasło',
        firstName: 'Imię',
        lastName: 'Nazwisko',
    },
    message: {
        added: 'Nowy użytkownik został dodany',
        edited: 'Użytkownik został zaktualizowany',
    },
}
//#endregion

//#region Form
enum FormFieldNames {
    Email = 'email',
    Password = 'password',
    RePassword = 'rePassword',
    FirstName = 'firstName',
    LastName = 'lastName',
}

interface FormFields {
    email: string
    password: string
    rePassword: string
    firstName: string
    lastName: string
}

interface FormErrors {
    email?: string
    password?: string
    rePassword?: string
    firstName?: string
    lastName?: string
}
//#endregion

//#region Props & State
interface RouteParams {
    userId: string
}
type ComponentProps = RouteComponentProps<RouteParams>

interface ComponentState {
    loading: boolean
    loaded: boolean
    errors: FormErrors
    fields: FormFields
    user: User | null
}

const initialState: ComponentState = {
    errors: {},
    fields: {
        email: '',
        password: '',
        rePassword: '',
        firstName: '',
        lastName: '',
    },
    loaded: false,
    loading: false,
    user: null,
}
//#endregion

class UserDetailsPage extends React.Component<ComponentProps, ComponentState> {
    static contextType = AppStateContext
    context!: React.ContextType<typeof AppStateContext>

    private userId: number

    constructor(props: ComponentProps) {
        super(props)

        this.state = initialState

        const { userId } = this.props.match.params
        this.userId = parseInt(userId, 10)
    }

    //#region Lifecycle
    public componentDidMount(): void {
        const { state, dispatch } = this.context
        const { menuItem, title } = state

        if (menuItem !== MenuItemType.Users) {
            dispatch(changeMenuItem(MenuItemType.Users))
        }

        if (title !== MenuItemType.Users) {
            dispatch(setTitle(MenuItemType.Users))
        }

        this.load()
    }

    public render(): React.ReactNode {
        const { loading, loaded, errors, fields } = this.state

        if (loading) {
            return (
                <Grid
                    container={true}
                    justifyContent="center"
                >
                    <CircularProgress />
                </Grid>
            )
        }

        if (!loaded) {
            return (
                <Grid
                    container={true}
                    justifyContent="center"
                >
                    <Typography color="error">{strings.error.unknown}</Typography>
                </Grid>
            )
        }

        return (
            <React.Fragment>
                <Grid
                    container={true}
                    spacing={1}
                    direction="row"
                >
                    <Grid
                        item={true}
                        xs={12}
                    >
                        <TextField
                            error={errors.email !== undefined}
                            helperText={errors.email}
                            id={FormFieldNames.Email}
                            name={FormFieldNames.Email}
                            value={fields.email}
                            multiline={false}
                            required={true}
                            inputProps={{ maxLength: 255 }}
                            fullWidth={true}
                            label={strings.label.email}
                            margin="none"
                            onChange={this.onTextFieldChange}
                            disabled={this.userId !== 0}
                        />
                    </Grid>

                    {this.userId === 0 && (
                        <Grid
                            item={true}
                            xs={12}
                        >
                            <TextField
                                error={errors.password !== undefined}
                                helperText={errors.password}
                                id={FormFieldNames.Password}
                                name={FormFieldNames.Password}
                                value={fields.password}
                                multiline={false}
                                required={true}
                                fullWidth={true}
                                label={strings.label.password}
                                margin="none"
                                type={'password'}
                                onChange={this.onTextFieldChange}
                            />
                        </Grid>
                    )}

                    {this.userId === 0 && (
                        <Grid
                            item={true}
                            xs={12}
                        >
                            <TextField
                                error={errors.rePassword !== undefined}
                                helperText={errors.rePassword}
                                id={FormFieldNames.RePassword}
                                name={FormFieldNames.RePassword}
                                value={fields.rePassword}
                                multiline={false}
                                required={true}
                                fullWidth={true}
                                label={strings.label.rePassword}
                                margin="none"
                                type={'password'}
                                onChange={this.onTextFieldChange}
                            />
                        </Grid>
                    )}

                    <Grid
                        item={true}
                        xs={12}
                    >
                        <TextField
                            error={errors.firstName !== undefined}
                            helperText={errors.firstName}
                            id={FormFieldNames.FirstName}
                            name={FormFieldNames.FirstName}
                            value={fields.firstName}
                            multiline={false}
                            required={true}
                            inputProps={{ maxLength: 128 }}
                            fullWidth={true}
                            label={strings.label.firstName}
                            margin="none"
                            onChange={this.onTextFieldChange}
                        />
                    </Grid>

                    <Grid
                        item={true}
                        xs={12}
                    >
                        <TextField
                            error={errors.lastName !== undefined}
                            helperText={errors.lastName}
                            id={FormFieldNames.LastName}
                            name={FormFieldNames.LastName}
                            value={fields.lastName}
                            multiline={false}
                            required={true}
                            inputProps={{ maxLength: 128 }}
                            fullWidth={true}
                            label={strings.label.lastName}
                            margin="none"
                            onChange={this.onTextFieldChange}
                        />
                    </Grid>

                    <Grid item={true}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.onSaveClick}
                        >
                            {strings.button.save}
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
    //#endregion

    //#region Private
    private load = async (): Promise<void> => {
        if (this.userId === 0) {
            this.setState({ loading: false, loaded: true })
        } else {
            try {
                this.setState({ loading: true })
                const response = await API.users.details(this.userId)
                this.setState({
                    user: response,
                    fields: {
                        email: response.email,
                        password: '',
                        rePassword: '',
                        firstName: response.firstName,
                        lastName: response.lastName,
                    },
                    loaded: true,
                    loading: false,
                })
            } catch (error) {
                this.setState({ loading: false })
            }
        }
    }

    private onTextFieldChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>
    ): void => {
        this.setState({
            fields: { ...this.state.fields, [event.target.name]: event.target.value },
        })
    }

    private onSaveClick = async (): Promise<void> => {
        const errors: FormErrors = this.validateFormFields(this.state.fields)
        this.setState({ errors })

        if (_.isEmpty(errors)) {
            if (this.userId === 0) {
                await this.create()
            } else {
                await this.update()
            }
        }
    }

    private create = async (): Promise<void> => {
        const { dispatch } = this.context
        const { fields } = this.state
        const { history } = this.props

        const request = new UserCreateRequest(fields.email, fields.password, fields.firstName, fields.lastName)

        try {
            dispatch(setInProgress())
            const response = await API.users.create(request)
            dispatch(setSuccess())
            history.replace(routesDetails.authenticated.user.to(response.id))
            this.userId = response.id
            dispatch(showMessage(strings.message.added))

            this.setState({
                user: response,
            })
        } catch (error) {
            dispatch(setFailure())

            if (error === null) {
                dispatch(showMessage(strings.error.unknown))
            }
        }
    }

    private update = async (): Promise<void> => {
        const { dispatch } = this.context
        const { fields } = this.state

        const request = new UserEditRequest(fields.firstName, fields.lastName)

        try {
            dispatch(setInProgress())
            const response = await API.users.update(this.userId, request)
            dispatch(setSuccess())
            dispatch(showMessage(strings.message.edited))

            this.setState({
                user: response,
            })
        } catch (error) {
            dispatch(setFailure())

            if (error === null) {
                dispatch(showMessage(strings.error.unknown))
            }
        }
    }

    private validateFormFields(fields: FormFields): FormErrors {
        const errors: FormErrors = {}

        if (this.userId === 0) {
            if (Validator.isEmpty(fields.email)) {
                errors.email = strings.error.emailRequired
            } else if (!Validator.isEmail(fields.email)) {
                errors.email = strings.error.emailInvalid
            } else if (!Validator.isLength(fields.email, { min: 1, max: 255 })) {
                errors.email = strings.error.emailLength
            }

            if (Validator.isEmpty(fields.password)) {
                errors.password = strings.error.passwordRequired
            } else if (!Validator.isLength(fields.password, { min: 8 })) {
                errors.password = strings.error.passwordLength
            } else if (fields.password !== fields.rePassword) {
                errors.rePassword = strings.error.rePasswordInvalid
            }
        }

        if (Validator.isEmpty(fields.firstName)) {
            errors.firstName = strings.error.firstNameRequired
        } else if (!Validator.isLength(fields.firstName, { min: 1, max: 128 })) {
            errors.firstName = strings.error.firstNameLength
        }

        if (Validator.isEmpty(fields.lastName)) {
            errors.lastName = strings.error.lastNameRequired
        } else if (!Validator.isLength(fields.lastName, { min: 1, max: 128 })) {
            errors.lastName = strings.error.lastNameLength
        }

        return errors
    }
    //#endregion
}

export default withAppCanvas(UserDetailsPage)
