import { Divider, Drawer, List, Theme } from '@mui/material'
import { createStyles, WithStyles, withStyles } from '@mui/styles'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import LabelIcon from '@mui/icons-material/Label'
import BusinessIcon from '@mui/icons-material/Business'
import PersonIcon from '@mui/icons-material/Person'
import { MenuItemType } from '../model/MenuItemType'
import DrawerListItem from './DrawerListItem'
import { AppStateContext } from '../state/appStateContext'
import { routesDetails } from '../routes/routesDetails'

export const drawerWidth = 260
export const drawerWidthCollapsed = 60

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) =>
    createStyles({
        drawer: {
            flexShrink: 0,
            width: drawerWidth,
            [theme.breakpoints.down('md')]: {
                width: drawerWidthCollapsed,
            },
        },
        drawerPaper: {
            width: drawerWidth,
            [theme.breakpoints.down('md')]: {
                width: drawerWidthCollapsed,
            },
        },
        toolbar: theme.mixins.toolbar,
    })

type ComponentProps = WithStyles<typeof styles> & RouteComponentProps

class AppDrawer extends React.Component<ComponentProps> {
    static contextType = AppStateContext
    context!: React.ContextType<typeof AppStateContext>

    public render(): React.ReactNode {
        const { menuItem } = this.context.state
        const { classes } = this.props

        const groups = [
            [
                {
                    icon: PersonIcon,
                    menuItem: MenuItemType.Users,
                    path: routesDetails.authenticated.users.path,
                },
                {
                    icon: BusinessIcon,
                    menuItem: MenuItemType.Branches,
                    path: routesDetails.authenticated.branches.path,
                },
                {
                    icon: LabelIcon,
                    menuItem: MenuItemType.Statuses,
                    path: routesDetails.authenticated.statuses.path,
                },
            ],
        ]

        return (
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.toolbar} />
                {groups.map((group, groupIndex) => (
                    <React.Fragment key={groupIndex}>
                        <List>
                            {group.map((item, itemIndex) => (
                                <DrawerListItem
                                    key={itemIndex}
                                    onChange={this.onMenuItemChange}
                                    menuItem={item.menuItem}
                                    isSelected={menuItem === item.menuItem}
                                    SvgIcon={item.icon}
                                    path={item.path}
                                />
                            ))}
                        </List>
                        {groupIndex < groups.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </Drawer>
        )
    }

    private onMenuItemChange = (_: MenuItemType, path: string): void => {
        const { history } = this.props

        history.push(path)
    }
}

export default withRouter(withStyles(styles)(React.memo(AppDrawer)))
