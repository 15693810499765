import React from 'react'
import { IconButton, Snackbar } from '@mui/material'
import { useAppStateContext } from '../../state/appStateContext'
import CloseIcon from '@mui/icons-material/Close'
import { hideMessage } from '../../state/messageActions'

const MessageSnackbar = (): React.ReactElement | null => {
    const { state, dispatch } = useAppStateContext()
    const { message } = state

    const onMessageClose = (): void => {
        dispatch(hideMessage())
    }

    return (
        <Snackbar
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
            }}
            open={message !== null}
            autoHideDuration={6000}
            onClose={onMessageClose}
            message={<span>{message}</span>}
            action={[
                <IconButton
                    key="close"
                    color="inherit"
                    onClick={onMessageClose}
                >
                    <CloseIcon />
                </IconButton>,
            ]}
        />
    )
}

export default React.memo(MessageSnackbar)
