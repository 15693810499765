import React from 'react'
import { AppBar, Button, Theme, Toolbar, Typography } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import { drawerWidth, drawerWidthCollapsed } from './AppDrawer'
import { useAppStateContext } from '../state/appStateContext'
import localStore from '../state/LocalStore'
import { logOutUser } from '../state/authActions'

const strings = {
    button: {
        logout: 'Wyloguj',
    },
    text: {
        appTitle: 'cfrental',
    },
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useStyles = (theme: Theme) =>
    makeStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        title: {
            flexGrow: 1,
            paddingLeft: drawerWidth,
            [theme.breakpoints.down('md')]: {
                paddingLeft: drawerWidthCollapsed,
            },
        },
    })

const GlobalAppBar = (): React.ReactElement => {
    const classes = useStyles(useTheme())()
    const { state, dispatch } = useAppStateContext()
    const { title } = state

    const onLogoutClick = async (): Promise<void> => {
        localStore.clearStore()
        dispatch(logOutUser())
    }

    return (
        <AppBar
            position="fixed"
            className={classes.appBar}
        >
            <Toolbar>
                <Typography
                    variant="h6"
                    color="inherit"
                    noWrap={true}
                    className={classes.title}
                >
                    {title !== null ? title : strings.text.appTitle}
                </Typography>
                <Button
                    color="inherit"
                    onClick={onLogoutClick}
                >
                    {strings.button.logout}
                </Button>
            </Toolbar>
        </AppBar>
    )
}

export default React.memo(GlobalAppBar)
