import * as Model from './response'
import * as Request from './request'
import client from './client'
import { UserStatus } from './response'

const authToken = async (username: string, password: string): Promise<Model.AccessToken> => {
    const data = {
        password,
        username,
    }
    const response = await client.post<Model.AccessToken>('oauth2/token', data)

    return response.data
}

interface PaginationParams {
    limit: undefined | number
    offset: undefined | number
}

//#region Branch
const branchesList = async (limit: number | null = null, offset: number | null = null): Promise<Model.Branch[]> => {
    const params: PaginationParams = {
        limit: undefined,
        offset: undefined,
    }

    if (limit !== null) {
        params.limit = limit
    }

    if (offset !== null) {
        params.offset = offset
    }

    const response = await client.get<Model.Branch[]>('branches', { params })

    return response.data
}

const branchesDetails = async (branchId: number): Promise<Model.Branch> => {
    const response = await client.get<Model.Branch>(`branches/${branchId}`)

    return response.data
}

const branchesCreate = async (data: Request.BranchRequest): Promise<Model.Branch> => {
    const response = await client.post<Model.Branch>('branches', data)

    return response.data
}

const branchesUpdate = async (branchId: number, data: Request.BranchRequest): Promise<Model.Branch> => {
    const response = await client.put<Model.Branch>(`branches/${branchId}`, data)

    return response.data
}

const branchesDelete = async (branchId: number): Promise<void> => {
    const response = await client.delete<void>(`branches/${branchId}`)

    return response.data
}
//#endregion

//#region Status
const statusesList = async (limit: number | null = null, offset: number | null = null): Promise<Model.Status[]> => {
    const params: PaginationParams = {
        limit: undefined,
        offset: undefined,
    }

    if (limit !== null) {
        params.limit = limit
    }

    if (offset !== null) {
        params.offset = offset
    }

    const response = await client.get<Model.Status[]>('statuses', { params })

    return response.data
}

const statusesDetails = async (statusId: number): Promise<Model.Status> => {
    const response = await client.get<Model.Status>(`statuses/${statusId}`)

    return response.data
}

const statusesCreate = async (data: Request.StatusRequest): Promise<Model.Status> => {
    const response = await client.post<Model.Status>('statuses', data)

    return response.data
}

const statusesUpdate = async (statusId: number, data: Request.StatusRequest): Promise<Model.Status> => {
    const response = await client.put<Model.Status>(`statuses/${statusId}`, data)

    return response.data
}

const statusesDelete = async (statusId: number): Promise<void> => {
    const response = await client.delete<void>(`statuses/${statusId}`)

    return response.data
}
//#endregion

//#region User
const usersList = async (limit: number | null = null, offset: number | null = null): Promise<Model.User[]> => {
    const params: PaginationParams = {
        limit: undefined,
        offset: undefined,
    }

    if (limit !== null) {
        params.limit = limit
    }

    if (offset !== null) {
        params.offset = offset
    }

    const response = await client.get<Model.User[]>('users', { params })

    return response.data
}

const usersDetails = async (userId: number): Promise<Model.User> => {
    const response = await client.get<Model.User>(`users/${userId}`)

    return response.data
}

const usersCreate = async (data: Request.UserCreateRequest): Promise<Model.User> => {
    const response = await client.post<Model.User>('users', data)

    return response.data
}

const usersUpdate = async (userId: number, data: Request.UserEditRequest): Promise<Model.User> => {
    const response = await client.put<Model.User>(`users/${userId}`, data)

    return response.data
}

const usersPassword = async (userId: number, data: Request.UserPasswordRequest): Promise<Model.User> => {
    const response = await client.post<Model.User>(`users/${userId}/password`, data)

    return response.data
}

const usersStatus = async (userId: number, status: UserStatus): Promise<Model.User> => {
    const response = await client.put<Model.User>(`users/${userId}/status/${status}`)

    return response.data
}

const usersDelete = async (userId: number): Promise<void> => {
    const response = await client.delete<void>(`users/${userId}`)

    return response.data
}
//#endregion

export const API = {
    auth: {
        token: authToken,
    },
    branches: {
        list: branchesList,
        details: branchesDetails,
        create: branchesCreate,
        update: branchesUpdate,
        delete: branchesDelete,
    },
    statuses: {
        list: statusesList,
        details: statusesDetails,
        create: statusesCreate,
        update: statusesUpdate,
        delete: statusesDelete,
    },
    users: {
        list: usersList,
        details: usersDetails,
        create: usersCreate,
        update: usersUpdate,
        password: usersPassword,
        status: usersStatus,
        delete: usersDelete,
    },
}
