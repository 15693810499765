import React from 'react'
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import _ from 'lodash'
import { RouteComponentProps } from 'react-router'
import Validator from 'validator'
import { API } from '../../api/api'
import withAppCanvas from '../../components/AppCanvas'
import { MenuItemType } from '../../model/MenuItemType'
import { AppStateContext } from '../../state/appStateContext'
import { changeMenuItem } from '../../state/menuItemActions'
import { setTitle } from '../../state/titleActions'
import { StatusRequest } from '../../api/request'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { routesDetails } from '../../routes/routesDetails'
import { showMessage } from '../../state/messageActions'

//#region UI
const strings = {
    button: {
        save: 'Zapisz',
    },
    error: {
        nameLength: 'Wymagana wartość w przedziale <1, 64> znaki',
        nameRequired: 'Nazwa jest wymagana',
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
    label: {
        name: 'Nazwa',
    },
    message: {
        added: 'Nowy status została dodany',
        edited: 'Status został zaktualizowany',
    },
}
//#endregion

//#region Form
enum FormFieldNames {
    Name = 'name',
}

interface FormFields {
    name: string
}

interface FormErrors {
    name?: string
}
//#endregion

//#region Props & State
interface RouteParams {
    statusId: string
}
type ComponentProps = RouteComponentProps<RouteParams>

interface ComponentState {
    loading: boolean
    loaded: boolean
    errors: FormErrors
    fields: FormFields
}

const initialState: ComponentState = {
    errors: {},
    fields: {
        name: '',
    },
    loaded: false,
    loading: false,
}
//#endregion

class StatusDetailsPage extends React.Component<ComponentProps, ComponentState> {
    static contextType = AppStateContext
    context!: React.ContextType<typeof AppStateContext>

    private statusId: number

    constructor(props: ComponentProps) {
        super(props)

        this.state = initialState

        const { statusId } = this.props.match.params
        this.statusId = parseInt(statusId, 10)
    }

    //#region Lifecycle
    public componentDidMount(): void {
        const { state, dispatch } = this.context
        const { menuItem, title } = state

        if (menuItem !== MenuItemType.Statuses) {
            dispatch(changeMenuItem(MenuItemType.Statuses))
        }

        if (title !== MenuItemType.Statuses) {
            dispatch(setTitle(MenuItemType.Statuses))
        }

        this.load()
    }

    public render(): React.ReactNode {
        const { loading, loaded, errors, fields } = this.state

        if (loading) {
            return (
                <Grid
                    container={true}
                    justifyContent="center"
                >
                    <CircularProgress />
                </Grid>
            )
        }

        if (!loaded) {
            return (
                <Grid
                    container={true}
                    justifyContent="center"
                >
                    <Typography color="error">{strings.error.unknown}</Typography>
                </Grid>
            )
        }

        return (
            <React.Fragment>
                <Grid
                    container={true}
                    spacing={1}
                    direction="row"
                >
                    <Grid
                        item={true}
                        xs={12}
                    >
                        <TextField
                            error={errors.name !== undefined}
                            helperText={errors.name}
                            id={FormFieldNames.Name}
                            name={FormFieldNames.Name}
                            value={fields.name}
                            multiline={false}
                            required={true}
                            inputProps={{ maxLength: 64 }}
                            fullWidth={true}
                            label={strings.label.name}
                            margin="none"
                            onChange={this.onTextFieldChange}
                        />
                    </Grid>

                    <Grid item={true}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.onSaveClick}
                        >
                            {strings.button.save}
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
    //#endregion

    //#region Private
    private load = async (): Promise<void> => {
        if (this.statusId === 0) {
            this.setState({ loading: false, loaded: true })
        } else {
            try {
                this.setState({ loading: true })
                const response = await API.statuses.details(this.statusId)
                this.setState({
                    fields: {
                        name: response.name,
                    },
                    loaded: true,
                    loading: false,
                })
            } catch (error) {
                this.setState({ loading: false })
            }
        }
    }

    private onTextFieldChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>
    ): void => {
        this.setState({
            fields: { ...this.state.fields, [event.target.name]: event.target.value },
        })
    }

    private onSaveClick = async (): Promise<void> => {
        const errors: FormErrors = this.validateFormFields(this.state.fields)
        this.setState({ errors })

        if (_.isEmpty(errors)) {
            if (this.statusId === 0) {
                await this.create()
            } else {
                await this.update()
            }
        }
    }

    private create = async (): Promise<void> => {
        const { dispatch } = this.context
        const { fields } = this.state
        const { history } = this.props

        const request = new StatusRequest(fields.name)

        try {
            dispatch(setInProgress())
            const response = await API.statuses.create(request)
            dispatch(setSuccess())
            history.replace(routesDetails.authenticated.status.to(response.id))
            this.statusId = response.id
            dispatch(showMessage(strings.message.added))
        } catch (error) {
            dispatch(setFailure())

            if (error === null) {
                dispatch(showMessage(strings.error.unknown))
            }
        }
    }

    private update = async (): Promise<void> => {
        const { dispatch } = this.context
        const { fields } = this.state

        const request = new StatusRequest(fields.name)

        try {
            dispatch(setInProgress())
            await API.statuses.update(this.statusId, request)
            dispatch(setSuccess())
            dispatch(showMessage(strings.message.edited))
        } catch (error) {
            dispatch(setFailure())

            if (error === null) {
                dispatch(showMessage(strings.error.unknown))
            }
        }
    }

    private validateFormFields(fields: FormFields): FormErrors {
        const errors: FormErrors = {}

        if (Validator.isEmpty(fields.name)) {
            errors.name = strings.error.nameRequired
        } else if (!Validator.isLength(fields.name, { min: 1, max: 64 })) {
            errors.name = strings.error.nameLength
        }

        return errors
    }
    //#endregion
}

export default withAppCanvas(StatusDetailsPage)
