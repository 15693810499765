import HomePage from '../modules/app/HomePage'
import BranchDetailsPage from '../modules/branches/BranchDetailsPage'
import BranchesPage from '../modules/branches/BranchesPage'
import NotFoundPage from '../modules/error/NotFoundPage'
import UnauthorizedPage from '../modules/error/UnauthorizedPage'
import LoginPage from '../modules/login/LoginPage'
import StatusDetailsPage from '../modules/statueses/StatusDetailsPage'
import StatusesPage from '../modules/statueses/StatusesPage'
import UserDetailsPage from '../modules/users/UserDetailsPage'
import UserPasswordPage from '../modules/users/UserPasswordPage'
import UsersPage from '../modules/users/UsersPage'

export const routesDetails = {
    authenticated: {
        homePage: {
            component: HomePage,
            path: '/',
        },
        users: {
            component: UsersPage,
            path: '/users',
        },
        user: {
            component: UserDetailsPage,
            path: '/users/:userId(\\d+)',
            to: (userId: number): string => `/users/${userId}`,
        },
        userPassword: {
            component: UserPasswordPage,
            path: '/users/:userId(\\d+)/password',
            to: (userId: number): string => `/users/${userId}/password`,
        },
        branches: {
            component: BranchesPage,
            path: '/branches',
        },
        branch: {
            component: BranchDetailsPage,
            path: '/branches/:branchId(\\d+)',
            to: (branchId: number): string => `/branches/${branchId}`,
        },
        statuses: {
            component: StatusesPage,
            path: '/statuses',
        },
        status: {
            component: StatusDetailsPage,
            path: '/statuses/:statusId(\\d+)',
            to: (statusId: number): string => `/statuses/${statusId}`,
        },
    },
    error: {
        notFound: {
            component: NotFoundPage,
            path: '/error/404',
        },
        unauthorized: {
            component: UnauthorizedPage,
            path: '/error/401',
        },
    },
    guest: {
        login: {
            component: LoginPage,
            path: '/login',
        },
    },
}
