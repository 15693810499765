import {
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
} from '@mui/material'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { API } from '../../api/api'
import { Status } from '../../api/response'
import withAppCanvas from '../../components/AppCanvas'
import { MenuItemType } from '../../model/MenuItemType'
import { AppStateContext } from '../../state/appStateContext'
import { changeMenuItem } from '../../state/menuItemActions'
import { showMessage } from '../../state/messageActions'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { setTitle } from '../../state/titleActions'
import AddIcon from '@mui/icons-material/Add'
import { BottomScrollListener } from 'react-bottom-scroll-listener'
import StatusTableRow from './StatusTableRow'
import { routesDetails } from '../../routes/routesDetails'

const strings = {
    button: {
        create: 'Utwórz',
    },
    column: {
        id: 'Id',
        name: 'Nazwa',
        actions: 'Akcje',
    },
    error: {
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
}

//#region Props & State
interface ComponentProps extends RouteComponentProps {}

interface ComponentState {
    items: Status[]
    loading: boolean
    loadMore: boolean
}

const initialState: ComponentState = {
    items: [],
    loading: false,
    loadMore: true,
}
//#endregion

class StatusesPage extends React.Component<ComponentProps, ComponentState> {
    static contextType = AppStateContext
    context!: React.ContextType<typeof AppStateContext>

    constructor(props: ComponentProps) {
        super(props)

        this.state = initialState
    }

    //#region Lifecycle
    public componentDidMount(): void {
        const { state, dispatch } = this.context
        const { menuItem, title } = state

        if (menuItem !== MenuItemType.Statuses) {
            dispatch(changeMenuItem(MenuItemType.Statuses))
        }

        if (title !== MenuItemType.Statuses) {
            dispatch(setTitle(MenuItemType.Statuses))
        }

        this.load()
    }

    public render(): React.ReactNode {
        const { loading, items } = this.state

        if (loading && items.length === 0) {
            return (
                <Grid
                    container={true}
                    justifyContent="center"
                >
                    <CircularProgress />
                </Grid>
            )
        }

        return (
            <BottomScrollListener
                onBottom={this.onDocumentBottom}
                offset={300}
                debounce={100}
                debounceOptions={{ leading: false, trailing: true }}
                triggerOnNoScroll={false}
            >
                <Paper elevation={2}>
                    <Toolbar>
                        <Tooltip title={strings.button.create}>
                            <IconButton onClick={this.onCreateClick}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                    <Table size="small">
                        <colgroup>
                            <col width="20%" />
                            <col width="50%" />
                            <col width="30%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>{strings.column.id}</TableCell>
                                <TableCell>{strings.column.name}</TableCell>
                                <TableCell>{strings.column.actions}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{items.map(this.renderItem)}</TableBody>
                    </Table>
                </Paper>
            </BottomScrollListener>
        )
    }
    //#endregion

    //#region Private
    private async load(): Promise<void> {
        const { items, loadMore, loading } = this.state

        if (loading || !loadMore) {
            return
        }

        this.setState({ loading: true })

        try {
            const response = await API.statuses.list(20, this.state.items.length)
            const statuses = items.concat(response)
            this.setState({ loading: false, items: statuses, loadMore: response.length > 0 })
        } catch (error) {
            this.setState({ loading: false })
        }
    }

    private onDocumentBottom = (): void => {
        this.load()
    }

    private onItemDeleteClick = async (item: Status): Promise<void> => {
        const { dispatch } = this.context

        try {
            dispatch(setInProgress())
            await API.statuses.delete(item.id)
            dispatch(setSuccess())

            const { items } = this.state
            this.setState({ items: items.filter((value) => value.id !== item.id) })
        } catch (error) {
            dispatch(setFailure())

            if (error === null) {
                dispatch(showMessage(strings.error.unknown))
            }
        }
    }

    private onCreateClick = (): void => {
        const { history } = this.props
        history.push(routesDetails.authenticated.status.to(0))
    }

    private onItemEditClick = (item: Status): void => {
        const { history } = this.props
        history.push(routesDetails.authenticated.status.to(item.id))
    }

    private renderItem = (item: Status): React.ReactNode => {
        return (
            <StatusTableRow
                key={item.id}
                item={item}
                onEditItem={this.onItemEditClick}
                onDeleteItem={this.onItemDeleteClick}
            />
        )
    }
    //#endregion
}

export default withAppCanvas(StatusesPage)
