import React from 'react'
import { CircularProgress, Portal, Theme, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useAppStateContext } from '../../state/appStateContext'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useStyles = (theme: Theme) =>
    makeStyles({
        background: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            height: '100%',
            left: 0,
            position: 'fixed',
            top: 0,
            width: '100%',
            willChange: 'opacity',
            zIndex: -1,
        },
        container: {
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            left: 0,
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: 1300,
        },
        progress: {
            alignItems: 'center',
            background: theme.palette.common.white,
            borderRadius: 5,
            display: 'flex',
            flex: '0 1 auto',
            flexDirection: 'column',
            height: 100,
            justifyContent: 'center',
            position: 'relative',
            width: 100,
        },
    })

function Loading(): React.ReactElement | null {
    const classes = useStyles(useTheme())()
    const { state } = useAppStateContext()

    if (state.progress > 0) {
        return (
            <Portal>
                <div className={classes.container}>
                    <div className={classes.background} />
                    <div className={classes.progress}>
                        <CircularProgress />
                    </div>
                </div>
            </Portal>
        )
    }

    return null
}

export default React.memo(Loading)
