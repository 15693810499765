import React from 'react'
import { TableCell, TableRow, Tooltip, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Branch } from '../../api/response'
import _ from 'lodash'

const strings = {
    button: {
        delete: 'Usuń',
        edit: 'Edytuj',
    },
}

interface ComponentProps {
    item: Branch
    onEditItem: (item: Branch) => void
    onDeleteItem: (item: Branch) => void
}

class BranchTableRow extends React.Component<ComponentProps> {
    //#region Lifecycle
    public shouldComponentUpdate(nextProps: Readonly<ComponentProps>): boolean {
        const currentItem = this.props.item
        const nextItem = nextProps.item

        return !_.isEqual(currentItem, nextItem)
    }

    public render(): React.ReactNode {
        const { item } = this.props

        return (
            <TableRow hover={true}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                    <Tooltip title={strings.button.edit}>
                        <IconButton onClick={this.onEditClick}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={strings.button.delete}>
                        <IconButton onClick={this.onDeleteClick}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        )
    }
    //#endregion

    //#region Private
    private onEditClick = (): void => {
        const { item, onEditItem } = this.props

        onEditItem(item)
    }

    private onDeleteClick = (): void => {
        const { item, onDeleteItem } = this.props

        onDeleteItem(item)
    }
    //#endregion
}

export default React.memo(BranchTableRow)
