import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import { useAppStateContext } from '../../state/appStateContext'
import { clearError } from '../../state/errorActions'

const strings = {
    button: {
        ok: 'OK',
    },
}

const ErrorDialog = (): React.ReactElement | null => {
    const { state, dispatch } = useAppStateContext()
    const { error } = state

    const onDialogOkClick = (): void => {
        dispatch(clearError())
    }

    return (
        <Dialog
            open={error !== null}
            disableEscapeKeyDown={true}
        >
            <DialogContent>
                <DialogContentText>{error !== null ? error.message : null}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onDialogOkClick}
                    autoFocus={true}
                >
                    {strings.button.ok}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(ErrorDialog)
