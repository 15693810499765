import React from 'react'
import { makeStyles, useTheme } from '@mui/styles'
import GlobalAppBar from './GlobalAppBar'
import AppDrawer from './AppDrawer'
import { CssBaseline, Theme } from '@mui/material'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useStyles = (theme: Theme) =>
    makeStyles({
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        root: {
            display: 'flex',
        },
        toolbar: theme.mixins.toolbar,
    })

type FunctionComponent<P extends {}> = (props: P) => React.ReactElement

const withAppCanvas = <P extends {}>(Component: React.ComponentType<P>): FunctionComponent<P> => {
    const appCanvas = (props: P): React.ReactElement => {
        const classes = useStyles(useTheme())()

        return (
            <div className={classes.root}>
                <CssBaseline />
                <GlobalAppBar />
                <AppDrawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <Component {...(props as P)} />
                </main>
            </div>
        )
    }

    return appCanvas
}

export default withAppCanvas
