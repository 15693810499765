export interface User {
    readonly id: number
    readonly email: string
    readonly firstName: string
    readonly lastName: string
    readonly status: UserStatus
}

export enum UserStatus {
    Active = 'active',
    Locked = 'locked',
}
