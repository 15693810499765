import React from 'react'
import { TableCell, TableRow, Tooltip, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import KeyIcon from '@mui/icons-material/Key'
import { User, UserStatus } from '../../api/response'
import _ from 'lodash'

const strings = {
    status: {
        active: 'Aktywny',
        locked: 'Zablokowany',
    },
    button: {
        delete: 'Usuń',
        edit: 'Edytuj',
        lock: 'Zablokuj',
        unlock: 'Odblokuj',
        password: 'Zmien hasło',
    },
}

interface ComponentProps {
    item: User
    onEditItem: (item: User) => void
    onChangePassword: (item: User) => void
    onDeleteItem: (item: User) => void
    onChangeStatus: (item: User, status: UserStatus) => void
}

class UserTableRow extends React.Component<ComponentProps> {
    //#region Lifecycle
    public shouldComponentUpdate(nextProps: Readonly<ComponentProps>): boolean {
        const currentItem = this.props.item
        const nextItem = nextProps.item

        return !_.isEqual(currentItem, nextItem)
    }

    public render(): React.ReactNode {
        const { item } = this.props

        return (
            <TableRow hover={true}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>
                    {item.firstName} {item.lastName}
                </TableCell>
                <TableCell>
                    {item.status === UserStatus.Active && strings.status.active}
                    {item.status === UserStatus.Locked && strings.status.locked}
                </TableCell>
                <TableCell>
                    {item.status === UserStatus.Active && (
                        <Tooltip title={strings.button.lock}>
                            <IconButton onClick={this.onLockClick}>
                                <LockIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {item.status === UserStatus.Locked && (
                        <Tooltip title={strings.button.unlock}>
                            <IconButton onClick={this.onUnlockClick}>
                                <LockOpenIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title={strings.button.edit}>
                        <IconButton onClick={this.onEditClick}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={strings.button.password}>
                        <IconButton onClick={this.onPasswordClick}>
                            <KeyIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={strings.button.delete}>
                        <IconButton onClick={this.onDeleteClick}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        )
    }
    //#endregion

    //#region Private
    private onEditClick = (): void => {
        const { item, onEditItem } = this.props

        onEditItem(item)
    }

    private onLockClick = (): void => {
        const { item, onChangeStatus } = this.props

        onChangeStatus(item, UserStatus.Locked)
    }

    private onUnlockClick = (): void => {
        const { item, onChangeStatus } = this.props

        onChangeStatus(item, UserStatus.Active)
    }

    private onPasswordClick = (): void => {
        const { item, onChangePassword } = this.props

        onChangePassword(item)
    }

    private onDeleteClick = (): void => {
        const { item, onDeleteItem } = this.props

        onDeleteItem(item)
    }
    //#endregion
}

export default React.memo(UserTableRow)
